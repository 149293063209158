<template>
  <div class="markdown" v-html="markdownToHtml"></div>
</template>

<script>
import marked from 'marked'

export default {
  name: 'Markdown',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    markdownToHtml() {
      if (!this.text) return null
      return marked.parse(this.text)
    },
  },
}
</script>

<style lang="scss" scoped>
/* @import '~markdown-air/css/air.css'; */

.markdown {
  color: #444;
  font-family: 'Montserrat', Helvetica, sans-serif;
  // font-weight: 500;
  //margin: 6rem auto 1rem;
  //max-width: 60rem;
  //text-align: center;
  line-height: 1.5;
  word-break: keep-all;

  /deep/ img {
    height: 50px;
    margin: 0 auto;
  }
  /deep/ a,
  /deep/ a:visited {
    color: #3498db;
  }
  /deep/ a:hover,
  /deep/ a:focus,
  /deep/ a:active {
    color: #2980b9;
  }

  /deep/ pre {
    background-color: #fafafa;
    padding: 1rem;
    text-align: left;
  }

  /deep/ blockquote {
    margin: 0;
    border-left: 5px solid #7a7a7a;
    padding: 1.33em;
    text-align: left;
  }

  /deep/ ul,
  /deep/ ol,
  /deep/ li {
    text-align: left;
    padding-left: 1.33em;
    margin-bottom: 0.45em;
    list-style: none;
  }

  /deep/ p {
    color: #777;
  }

  /deep/ img,
  /deep/ canvas,
  /deep/ iframe,
  /deep/ video,
  /deep/ svg,
  /deep/ select,
  /deep/ textarea {
    max-width: 100%;
  }

  /deep/ p {
    font-size: 1rem;
    margin-bottom: 1.3rem;
  }

  /deep/ h1,
  /deep/ h2,
  /deep/ h3,
  /deep/ h4,
  /deep/ h5 {
    margin: 1.414rem 0 0.5rem;
    font-weight: inherit;
    line-height: 1.42;
  }

  /deep/ h1 {
    margin-top: 0;
    font-size: $size-2;
  }

  /deep/ h2 {
    font-size: $size-3;
  }

  /deep/ h3 {
    font-size: $size-4;
  }

  /deep/ h4 {
    font-size: $size-5;
  }

  /deep/ h5 {
    font-size: $size-6;
  }

  /deep/ h6 {
    font-size: $size-7;
  }

  /deep/ small {
    font-size: $size-8;
  }

  /deep/ table,
  /deep/ th,
  /deep/ tr,
  /deep/ td {
    padding: 0.5rem;
  }
}
</style>
